<template>
    <Suspense>
        <div class="mx-0 md:px-16 bg-gray-100">
            
            <div class="mx-auto max-w-2xl py-16 px-4 sm:py-24  lg:max-w-7xl lg:px-8">
                <PageHeader heade="Products"/>
                <div class="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3  xl:gap-x-8">
                    <div v-for="product, i in products" :key="i" class="overflow-hidden rounded-xl transition-all duration-200 shadow-sm hover:shadow-lg ease-in-out cursor-pointer h-full">
                        <router-link   :to="{name: 'product', params: {id: product.id} }" >
                            <div
                                class="aspect-w-1 aspect-h-1 w-full overflow-hidden xl:aspect-w-7 xl:aspect-h-8">
                                <img :src="product.image" :alt="product.image"
                                    class=" h-72 md:h-80 w-full object-cover object-center group-hover:opacity-85 scale-0 hover:scale-50" />
                            </div>
                            <div class=" p-4 bg-gray-100 h-32">
                                <h3 class="mt-4 text-lg text-gray-700">{{ product.title }}</h3>
                                <p class="mt-2 text-lg text-center  text-gray-800 font-bold">{{ product.price }} Tsh</p>
                            </div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </Suspense>
</template>
  
<script setup>
import PageHeader from '@/components/PageHeader.vue';

var products = [
    {
        'id': 'Qm Beauty Extra virgin coconut oil 8.50z 250ml',
        'title': 'Qm Beauty Extra virgin coconut oil 8.50z 250ml',
        'description': 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quo voluptates rerum necessitatibus, alias iusto praesentium deleniti nam ullam fugiat ducimus facere recusandae suscipit sapiente cumque sint rem repellendus at',
        "price": 25000,
        'image': require('../../assets/images/Qm_beauty_Extra_Virgin_Coconut_Oil_1.png')
    },
    {
        'id': 'Qm Beauty natural scrub 7oz 200g',
        'title': 'Qm Beauty natural scrub 7oz 200g',
        'description': 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quo voluptates rerum necessitatibus, alias iusto praesentium deleniti nam ullam fugiat ducimus facere recusandae suscipit sapiente cumque sint rem repellendus at',
        "price": 20000,
        'image': require('../../assets/images/Qm_Beauty_Natural_Scrub_1.png')

    },
    {
        'id': 'Qm Beauty hair gel 7oz 200g',
        'title': 'Qm Beauty hair gel 7oz 200g',
        'description': 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quo voluptates rerum necessitatibus, alias iusto praesentium deleniti nam ullam fugiat ducimus facere recusandae suscipit sapiente cumque sint rem repellendus at',
        "price": 20000,
        'image': require('../../assets/images/Qmbeautyhairgel1.png')
    },
    {
        'id': 'Qm Beauty Pure coconut oil 8.50z 250ml',
        'title': 'Qm Beauty Pure coconut oil 8.50z 250ml',
        'description': 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quo voluptates rerum necessitatibus, alias iusto praesentium deleniti nam ullam fugiat ducimus facere recusandae suscipit sapiente cumque sint rem repellendus at',
        "price": 12000,
        'image': require('../../assets/images/Qm_Beauty_Pure_Coconut_Oil_1.png')
    },
    {
        'id': 'Qm Beauty Regular coconut oil 8.5oz 250ml',
        'title': 'Qm Beauty Regular coconut oil 8.5oz 250ml',
        'description': 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quo voluptates rerum necessitatibus, alias iusto praesentium deleniti nam ullam fugiat ducimus facere recusandae suscipit sapiente cumque sint rem repellendus at',
        "price": 15000,
        'image': require('../../assets/images/Regular_coconut_oil1.png')
    },
    {
        'id': 'Qm Beauty Natural Glowing face cream 3.4oz 100ml',
        'title': 'Qm Beauty Natural Glowing face cream 3.4oz 100ml',
        'description': 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quo voluptates rerum necessitatibus, alias iusto praesentium deleniti nam ullam fugiat ducimus facere recusandae suscipit sapiente cumque sint rem repellendus at',
        "price": 25000,
        'image': require('../../assets/images/Qm_beauty_Natural_glowing_face_cream_1.png')
    },
    {
        'id': 'Qm Beauty Carrots & turmeric Body Oil 8.5oz 250ml',
        'title': 'Qm Beauty Carrots & turmeric Body Oil 8.5oz 250ml',
        'description': 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Vitae quo voluptates rerum necessitatibus, alias iusto praesentium deleniti nam ullam fugiat ducimus facere recusandae suscipit sapiente cumque sint rem repellendus at',
        "price": 20000,
        'image': require('../../assets/images/Qmbeautycarrotsandturmericbodyoil_1.png')
    },
];

</script>
<style >
    
</style>