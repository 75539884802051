<template lang="">
    <PageHeader heade="Our Agents"/>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 items-start justify-between px-4 md:px-24 py-10" >
        <div class="w-fit flex items-start justify-start gap-4 text-left" v-for="(agent, i) in agents" :key="i">
            <i class="block text-chocrate">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-10 h-10">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z" />
                </svg>
            </i>
            <div class="text-base flex flex-col gap-1">
                <p>{{agent.location}}</p>
                <h2>{{agent.name}}</h2>
                <a :href="'tel:+'+ agent.phone" class="text-xl font-medium" v-if="!agent.phone.length">+{{agent.phone}}</a>
                <a class="text-xl font-medium" v-else v-for="phones, inde in agent.phone" :key="inde" :href="'tel:+'+ phones" >+{{phones}} <br/></a>

            </div>
        </div>
        
    </div>
</template>
<script setup>
import PageHeader from '@/components/PageHeader.vue';
    const agents = [
        {
            "name": "Mwanza",
            "location" : "CITY MALL",
            "phone" : 255683834444
        },
        {
            "name": "Dar es salaam",
            "location" : "MWANANYAMALA",
            "phone" : 255715222080
        },
        {
            "name": "Dar es salaam",
            "location" : "MIKOCHENI PV COLLECTON",
            "phone" : 255756409406
        },
        {
            "name": "Dar es salaam",
            "location" : "MWENGE TREND SHOP",
            "phone" : 255686009922
        },
        {
            "name": "Dar es salaam",
            "location" : "UPANGA",
            "phone" : 255717070845
        },
        {
            "name": "Dar es salaam",
            "location" : "TABATA",
            "phone" : 255717070845
        },
        {
            "name": "Dar es salaam",
            "location" : "KIGAMBONI",
            "phone" : 255765617952
        },
        {
            "name": "Dar es salaam",
            "location" : "KIGAMBONI DUBAI SHOP",
            "phone" : 255622622951
        },
        {
            "name": "Dar es salaam",
            "location" : "TANGA",
            "phone" : 255629297613
        },
        {
            "name": "MWANZA",
            "location" : "MWANZA",
            "phone" : 255718866028

        },
        {
            "name": "Dar es salaam",
            "location" : "UDSM",
            "phone" : 255754919835

        },
        {
            "name": "Dar es salaam",
            "location" : "ILALA BUNGONI",
            "phone" : 255713418399

        },
        {
            "name": "ZANZIBAR",
            "location" : "ZANZIBAR MAGOMENI",
            "phone" : 255776440519

        },
        {
            "name": "ZANZIBAR",
            "location" : "ZANZIBAR MOMBASA",
            "phone" : 255776438616

        },
        {
            "name": "ZANZIBAR",
            "location" : "ZANZIBAR VUGA",
            "phone" : 255776440519

        },
        {
            "name": "Dar es salaam",
            "location" : "SHOPPERS PLAZA MIKOCHENI",
            "phone" : 255719832443

        },
        {
            "name": "Dar es salaam",
            "location" : "KARIAKOO",
            "phone" : 255754341143

        },
        {
            "name": "LINDI",
            "location" : "LINDI",
            "phone" : 255626253278

        },
        {
            "name": "Dar es salaam",
            "location" : "TEGETA",
            "phone" : 255743637631

        },
        {
            "name": "Dar es salaam",
            "location" : "MIKOCHENI",
            "phone" : [255713620502, 255692285798]

        }
    ]
</script>
<style lang="">
    
</style>