<template lang="">
    <div class="bg-gray-50 text-white myBg  grid grid-cols-1 md:grid-cols-2 items-center justify-center">
        <div class="overflow-hidden h-screen bg-dark bg-opacity-30 flex items-start justify-center  flex-col px-4 md:pl-28">
            <span class="text-8xl text-left font-bold text-transparent bg-clip-text bg-gradient-to-b to-chocrate via-orange from-chocrate ">QM Beauty </span> 
            <p class="py-6 text-2xl  text-left text-white font-medium">Beauty is our proud, Love yourself</p>
        </div>
        <div class=" bg-dark bg-opacity-30 h-screen pt-20 hidden md:block">
            <carousel :items-to-show="1" :wrapAround="true" :transition="200" :autoplay="3000" :pauseAutoplayOnHover="true" >
                <slide v-for="service, i in introService" :key="i" class="">
                    <img :src="service.image" class="flex-shrink-0 rounded h-80 object-cover" />
                </slide>
                <template #addons>
                    <navigation />
                    <pagination />
                </template>
            </carousel>
            <div class="flex flex-col justify-center">
                <p class="text-xl py-6
                 font-medium text-center">Get our bect offer this month</p>
                <button class="rounded-md border-2 bg-chocrate   border-chocrate px-6 py-2 text-white hover:bg-chocrate mx-auto hover:bg-opacity-40 transition-all duration-200 uppercase">Shop now</button>
            </div>
        </div>
        
    </div>
    <ProductsView/>
    <ServicesView />
    <ArgentViewVue/>
    <AboutViewVue/>
    
</template>
<script setup>
import ProductsView from '../products/ProductsView.vue';
import ServicesView from '../services/ServicesView.vue';
import ArgentViewVue from '../agents/ArgentView.vue';
import AboutViewVue from '../about/AboutView.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

var introService = [
    {'image': require('./../../assets/images/intro/QMP_0360.png')},
    {'image': require('./../../assets/images/intro/QMP_0386.png')},
    {'image': require('./../../assets/images/intro/QMP_0413.png')},
    {'image': require('./../../assets/images/intro/QMP_0435.png')}
]

</script>
<style >
.myBg {
    background-image: url('./../../assets/images/background_image.jpg');
    /* background: white; */
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100%;

}
</style>
