
<template>
    <Suspense>
    <div class="bg-gray-100" >
        <h1 class=" text-black text-2xl text-center pt-6 sm:pb-10 pb-12"></h1>
        <PageHeader heade="QM Beauty Services Catalogue"/>
        <carousel  :items-to-show="4" :wrapAround="true" :transition="200" :autoplay="3000" :pauseAutoplayOnHover="true"
            :breakpoints="breakpoints">
            <slide v-for="service, i in services" :key="i">
                <div  class="relative h-80 w-full rounded-none">
                    <img :src="service.image" class="rounded bg-green-700 h-full w-full object-cover" />
                    <router-link :to="{name: 'service', params: {id: service.title} }"
                        class="absolute bottom-4 right-4 rounded-full bg-white bg-opacity-50 border p-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                            stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round"
                                d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                        </svg>
                    </router-link>
                    <h3
                        class="absolute bg-chocrate bg-opacity-40 rounded-r-full py-1 pr-3 pl-2 text-lg left-0 bottom-0 text-gray-100 font-semibold">
                        {{ service.title }}</h3>
                </div>
            </slide>
            <template #addons>
                <navigation />
                <pagination />
            </template>
        </carousel>
        <div class="flex items-center justify-end"><router-link to="services"
                class="bg-chocrate px-6 py-2 text-white font-medium text-base rounded-full text-right my-10 mr-12">View
                more</router-link></div>
        <div class="px-4 md:px-28 pb-10">
            <SubHeader subHead="QM BEAUTY SPECIAL PACKAGES" />
            <div class="grid grid-cols-1 md:grid-cols-2 ">
                <ul class="flex flex-col gap-10 font-medium ">
                    <li>Full Body Scrub + Light Facial + Foot Massage</li>
                    <li>Min Deep Cleansing Facial + Under Arm Waxing</li>
                    <li>Manicure + Pedicure + Foot Spa + Foot Massage</li>
                    <li>Hair Wash + Hair Trim + Hair Treatment + Blow Dry + Simple Make Up</li>
                    <li>Foot Spa Treatment</li>
                    <li>Face Examination(Face Inspection)</li>
                    <li>Wrinkle Removal</li>
                    <li>Collagen Face Massage</li>
                    <li>Glowing Skin Facial Treatment</li>
                </ul>
                <div class="md:pt-10">
                    <img src="../../assets/images/services_photo/skin_Management_system_Beauty_Machine.jpg" class="rounded-lg mt-10 md-mt-0"/>
                </div>
            </div>
            <div class="flex items-center justify-end"><router-link to="special_package"
                    class="bg-chocrate px-6 py-2 text-white font-medium text-base rounded-full text-right my-10">View
                    more special package</router-link></div>
        </div>
    </div>
</Suspense>
</template>

<script setup>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import PageHeader from '@/components/PageHeader.vue';
import SubHeader from '@/components/SubHeader.vue';
import { ref } from 'vue'
const breakpoints = ref({})
const services = ref([])

services.value = [
    {
        'id': '1',
        'title': 'Body Scrub',
        'image': require('../../assets/images/services_photo/body_scrub.jpg')
    },
    {
        'id': '2',
        'title': 'Facial Treatments',
        'image': require('../../assets/images/services_photo/Facial_treatment.webp')

    },
    {
        'id': '3',
        'title': 'Hair Braiding',
        'image': require('../../assets/images/services_photo/hairbraidedq.jpg')
    },
    {
        'id': '4',
        'title': 'Hair Treatment',
        'image': require('../../assets/images/services_photo/hair_treatment33.webp')
    },
    {
        'id': '5',
        'title': 'Henna',
        'image': require('../../assets/images/services_photo/henna.jpg')
    },
    {
        'id': '6',
        'title': 'Makeup',
        'image': require('../../assets/images/services_photo/makups1.jpg')
    },
    {
        'id': '7',
        'title': 'Waxing',
        'image': require('../../assets/images/services_photo/waxing.jpg')
    },
    {
        'id': '8',
        'title': 'Nails',
        'image': require('../../assets/images/services_photo/nails2.jpg')
    },
    {
        'id': '8',
        'title': 'Hair Relaxer',
        'image': require('../../assets/images/services_photo/hair_treatment33.webp')
    },
]
breakpoints.value = {
    10: {
        itemsToShow: 1.2,
        snapAlign: 'center',
    },
    640: {
        itemsToShow: 2,
        snapAlign: 'center',
    },
    1024: {
        itemsToShow: 4,
        snapAlign: 'start',
    },
}

</script>
