
<template>
    <div class="px-4 md:px-28 py-10">
        <PageHeader :heade="id" class="text-left"/>
        <div class="flex flex-col gap-y-10">
            <div class="grid grid-cols-1 md:grid-cols-2 border-b  gap-8">
                <SubHeader :subHead="services[indexOfMe].title" class="col-span-1 md:col-span-2 text-center" />
                <div>
                    <img :src="services[indexOfMe].image" class="bg-gray-200 w-full h-96 object-cover rounded-lg" />
                </div>
                <div class="h-fit w-full">
                    <table class="table-fixed border rounded-md shadow-md border-collapse text-left w-full">
                        <thead>
                            <tr class="border bg-gray-200 ">
                                <th class="py-2 pl-6 w-8">#</th>
                                <th class="py-2 pl-6">Service</th>
                                <th class="py-2 pl-6 pr-2 text-right">Price(Tsh)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="subservice , k in services[indexOfMe].services" :key="k" class="border ">
                                <td class="py-2 pl-6 w-8">{{ k+1 }}</td>
                                <td class="py-2 pl-6">{{ subservice.service }}</td>
                                <td class="py-2 pl-6 pr-2 text-right">
                                    <span class="font-bold " v-if="!subservice.price.length">{{
                                    subservice.price.toLocaleString() }}</span>
                                    <span class="font-bold " v-else v-for="prices, ind in subservice.price"
                                        :key="ind">{{ prices.toLocaleString() }} <br> </span>
                                </td>
                            </tr>

                        </tbody>
                    </table>

                </div>
            </div>
        </div>
    </div>

</template>


<script setup>
import PageHeader from '@/components/PageHeader.vue';
import { useRoute } from 'vue-router';
import { ref } from 'vue'


// const route = useRoute();
const indexOfMe = ref(null)
const id = useRoute().params.id;
const services = ref([])


services.value = [
    {
        'id': '1',
        'title': 'Body Scrub',
        'services': [
            {
                'service': 'Full Body Scrub(QM BEAUTY COFFEE SCRUB)',
                'price': 85000
            },
            {
                'service': 'Full Tanga Body Scrub(a mix of sandal wood n dry flowers + special oil)',
                'price': 70000
            },
            {
                'service': 'Last Touch Bridal Package(Tanga Singo + QM Beauty Facial + Foot Spa)',
                'price': 105000
            },
        ],
        'image': require('../../assets/images/services_photo/body_scrub.jpg')
    },
    {
        'id': '2',
        'title': 'Facial Treatments',
        'services': [
            {
                'service': 'Magic Hands Treatment',
                'price': 30000
            },
            {
                'service': 'Swahili Natural Instant facial Treatment',
                'price': 25000
            },
            {
                'service': 'QM Beauty Facial Treatment (Coffee Scrub)',
                'price': 25000
            },
            {
                'service': 'QM Beauty Natural Whitening Facial',
                'price': 35000
            }
        ],
        'image': require('../../assets/images/services_photo/Facial_treatment.webp')

    },
    {
        'id': '3',
        'title': 'Hair Braiding',
        'services': [
            {
                'service': 'Cornrow Braids(Nywele za mkono)',
                'price': [7000, 15000]
            },
            {
                'service': 'Goddess Braids(Yebo yebo)',
                'price': [20000, 30000, 50000, 60000]
            },
            {
                'service': 'Box Braids',
                'price': [30000, 45000, 55000, 60000, 75000]
            },
            {
                'service': 'Knotless Braids',
                'price': 60000
            },
            {
                'service': 'Masai Braids',
                'price': [45000, 55000]
            },
            {
                'service': 'Senegalese Twist Braids',
                'price': 70000
            },
            {
                'service': 'Micro Braids',
                'price': 45000
            },
            {
                'service': 'Tree Braids',
                'price': 45000
            },
            {
                'service': 'Natural Hair Braids',
                'price': [7000, 15000]
            },
            {
                'service': 'Passion Twist',
                'price': 70000
            },
            {
                'service': 'Butterfly Locs',
                'price': 60000
            },
            {
                'service': 'Weaving',
                'price': 30000
            },
            {
                'service': 'kinky Twist',
                'price': 50000
            },
            {
                'service': 'Natural Twist',
                'price': 30000
            },
            {
                'service': 'Selfie',
                'price': 50000
            },
            {
                'service': 'Afro kinky',
                'price': 70000
            },
            {
                'service': 'Yebo Kinky',
                'price': 50000
            },
            {
                'service': 'Curl Twist',
                'price': 80000
            },
            {
                'service': 'Curl Braids',
                'price': 85000
            },
            {
                'service': 'Crochet',
                'price': 50000
            },
            {
                'service': 'Simple Braid',
                'price': 20000
            }
        ],
        'image': require('../../assets/images/services_photo/hairbraidedq.jpg')
    },
    {
        'id': '4',
        'title': 'Hair Treatment & Relaxer',
        'services': [
            {
                'service': 'Magic Hands Treatment',
                'price': 30000
            },
            {
                'service': 'Swahili Natural Instant facial Treatment',
                'price': 25000
            },
            {
                'service': 'QM Beauty Facial Treatment (Coffee Scrub)',
                'price': 25000
            },
            {
                'service': 'QM Beauty Natural Whitening Facial',
                'price': 35000
            }
        ],
        'image': require('../../assets/images/services_photo/hair_treatment33.webp')
    },
    {
        'id': '8',
        'title': 'Hair Relaxer',
        'services': [
            {
                'service': 'QM BEAUTY Hair Steaming (Natural Products)',
                'price': 0
            },
            {
                'service': 'Hair Steaming(Our products)',
                'price': 25000
            },
            {
                'service': 'Hair Steaming(Clients products)',
                'price': 18000
            },
            {
                'service': 'Highlight',
                'price': 80000
            },
            {
                'service': 'Hair Coloring(Normal color)',
                'price': 20000
            },
            {
                'service': 'Hair Relaxer(Our products)',
                'price': 25000
            },
            {
                'service': 'Hair Relaxer(Clients products)',
                'price': 18000
            },
            {
                'service': 'Wash & Set',
                'price': 15000
            },
            {
                'service': 'Flat Iron',
                'price': 20000
            },
            {
                'service': 'Straw(mirija)',
                'price': 30000
            },
            {
                'service': 'Tong',
                'price': 30000
            }
        ],
        'image': require('../../assets/images/services_photo/hair_treatment33.webp')
    },
    {
        'id': '5',
        'title': 'Henna',
        'services': [
            {
                'service': 'Simple Henna (hands)',
                'price': 20000
            },
            {
                'service': 'Simple Henna (legs)',
                'price': 25000
            },
            {
                'service': 'Bridal Henna',
                'price': 170000
            }
        ],
        'image': require('../../assets/images/services_photo/henna.jpg')
    },
    {
        'id': '6',
        'title': 'Makeup',
        'services': [
            {
                'service': 'Simple Make Up',
                'price': 40000
            },
            {
                'service': 'Simple With Lashes',
                'price': 50000
            },
            {
                'service': 'Classic',
                'price': 60000
            },
            {
                'service': 'Bridal Package 1',
                'price': 250000
            },
            {
                'service': 'Bridal Package 2',
                'price': 200000
            },
            {
                'service': 'Simple Hair Style',
                'price': 10000
            },
            {
                'service': 'Classic Hair Style',
                'price': [20000, 30000, 40000, 50000, 60000]
            },
        ],
        'image': require('../../assets/images/services_photo/makups1.jpg')
    },
    {
        'id': '7',
        'title': 'Waxing',
        'services': [
            {
                'service': 'Bikini Waxing(sugar wax)',
                'price': 25000
            },
            {
                'service': 'Bikini Waxing(bee wax)',
                'price': 25000
            },
            {
                'service': 'Under Arm Waxing(sugar wax)',
                'price': 15000
            },
            {
                'service': 'Under Arm Waxing(sugar wax)',
                'price': 20000
            },
            {
                'service': 'Legs & Arms Waxing(sugar wax)',
                'price': 30000
            },
            {
                'service': 'Legs & Arms Waxing(bee wax)',
                'price': 35000
            },
            {
                'service': 'Chin & Upper Lip Waxing(sugar wax)',
                'price': 10000
            },
            {
                'service': 'Chin & Upper Lip Waxing(sugar wax)',
                'price': 15000
            },
            {
                'service': 'Full Body Waxing(sugar wax)',
                'price': 55000
            },
            {
                'service': 'Full Body Waxing(sugar wax)',
                'price': 65000
            }

        ],
        'image': require('../../assets/images/services_photo/waxing.jpg')
    },
    {
        'id': '8',
        'title': 'Nails',
        'services': [
            {
                'service': 'Full Nail Extension & Gel',
                'price': 40000
            },
            {
                'service': 'Normal Nail Extension',
                'price': 18000
            },
            {
                'service': 'Nail Art Design',
                'price': [50000, 100000]
            },
            {
                'service': 'Nail Extension Remover',
                'price': 15000
            },
            {
                'service': 'Gel Extension Remover',
                'price': 5000
            }
        ],
        'image': require('../../assets/images/services_photo/nails2.jpg')
    },
]


indexOfMe.value = services.value.findIndex(object => {
    return object.title === id;
});



console.log('indexValue ' + indexOfMe.value)

console.log(services)
</script>
