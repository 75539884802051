<template>
    <div class=" bg-gradient-to-tr from-orange to-chocrate  px-4 md:px-28 pb-10">
        <div class="py-10 w-full">
            <img src="../../assets/images/services_photo/premium.jpg" alt="" class="h-20 mx-auto">
            <PageHeaderVue heade="QM BEAUTY SPECIAL PACKAGES." class="text-white" />
            <div>
                <div v-for="specialPackage, ind in specialPackages" :key="ind" class="grid grid-cols-1 md:grid-cols-2 py-10 gap-10 items-start justify-start">
                    
                    <div class="h-fit w-full">
                        <h2 class="pb-4 text-xl">{{ specialPackage.service }}</h2>
                        <table class="table-fixed border rounded-md shadow-md border-collapse text-left w-full">
                            <thead>
                                <tr class="border bg-gray-200 ">
                                    <th class="py-2 pl-6 w-8">#</th>
                                    <th class="py-2 pl-6">Service</th>
                                    <th class="py-2 pl-6 pr-2 text-right">Price(Tsh)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="specialService, i in specialPackage.services" :key="i" class="border ">
                                    <td class="py-2 pl-6 w-8">{{ i+1 }}</td>
                                    <td class="py-2 pl-6">{{ specialService.service }}</td>
                                    <td class="py-2 pl-6 pr-2 text-right">
                                        <span class="font-bold " v-if="!specialService.price.length">{{
                                        specialService.price.toLocaleString() }}</span>
                                        <span class="font-bold " v-else v-for="prices, ind in specialService.price"
                                            :key="ind">{{ prices.toLocaleString() }} <br> </span>
                                    </td>
                                </tr>

                            </tbody>
                        </table>
                    </div>
                    <img :src="specialPackage.image" class="h-72 md:h-96 w-full object-cover rounded-xl" />
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
// import SubHeader from '@/components/SubHeader.vue';
import PageHeaderVue from '@/components/PageHeader.vue';

const specialPackages = [
    {
        'image': require('../../assets/images/services_photo/full_body_massage.jpg'),
        'service' : '',
        'services': [
            {
                'service': 'Full Body Scrub + Light Facial + Foot Massage',
                'price': 130000
            },
            {
                'service': '30 Min Deep Cleansing Facial + Under Arm Waxing',
                'price': 45000
            },
            {
                'service': 'Manicure + Pedicure + Foot Spa + Foot Massage',
                'price': 50000
            },
            {
                'service': 'Hair Wash + Hair Trim + Hair Treatment + Blow Dry + Simple Make Up',
                'price': 65000
            }
        ]
    },
    {
        'image': require('../../assets/images/services_photo/foot_massage.jpg'),
        'service': 'Foot Spa Treatment',
        'services': [
            {
                'service': 'Foot Spa Treatment',
                'price': 40000
            },
            {
                'service': 'Manicure & Nail Polish',
                'price': 15000
            },
            {
                'service': 'Manicure & Gel Polish',
                'price': 20000
            },
            {
                'service': 'Pedicure & Nail Polish',
                'price': 25000
            },
            {
                'service': 'Pedicure & Gel Polish',
                'price': 30000
            }
        ],

    },
    {

        'image': require('../../assets/images/services_photo/skin_Management_system_Beauty_Machine.jpg'),
        'service': 'We offer services in providing, Skin detection, Nutrition introduction, Exfoliate, Cleansing hydrating, Soothes the skin, Eye care, Face lift, Remove wrinkles, Skin rejuvenation',
        'services': [
            {
                'service': 'Face Examination(Face Inspection)',
                'price': 70000
            },
            {
                'service': 'Wrinkle Removal',
                'price': 90000
            },
            {
                'service': 'Collagen Face Massage',
                'price': 155000
            },
            {
                'service': 'Glowing Skin Facial Treatment',
                'price': 55000
            }
        ],
    }

]
</script>
<style>

</style>