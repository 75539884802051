import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import HomeView from '../views/Home/HomeView.vue'
import ProductsView from '../views/products/ProductsView.vue'
import ProductDetails from '@/views/products/ProductShow.vue'
import AgentView from '../views/agents/ArgentView.vue'
import ServiceView from '../views/services/ServiceShow.vue'
import AboutView from '../views/about/AboutView.vue'
import DetailedDetailed from '../views/services/ServicesViewInDetail.vue'
import SpecialPackages from '../views/services/SpacialPackages.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/products',
    name: 'products',
    component: ProductsView
  },
  {
    path: '/product/:id',
    name: 'product',
    component: ProductDetails
  },
  {
    path: '/agents',
    name: 'agents',
    component: AgentView
  },
  {
    path: '/services',
    name: 'services',
    component: DetailedDetailed
  },
  {
    path: '/special_package',
    name: 'SpecialPackages',
    component: SpecialPackages
  },
  {
    path: '/service/:id',
    name: 'service',
    component: ServiceView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach(() => {
  window.scrollTo(0, 0)
 
  // ...
})
export default router
