<template lang="">
  <div class="sticky top-0 z-50">
    <nav class="hidden md:flex items-center justify-between sticky top-0  z-50 py-2 px-4 md:px-28 bg-dark" >
        <router-link to="/">
            <img src="../assets/images/qmbeauty_logo.png" alt="" class="w-28">
        </router-link>
        <ul class="flex gap-6 items-center text-white">
            <router-link :to="navLink.link"  v-for="navLink, index in navLinks" :key="index">{{navLink.name}}</router-link>
            
        </ul>
        <router-link to="/about" class="rounded-md border-2 border-chocrate px-6 py-2 text-white ">Talk to us</router-link>
    </nav>
    <nav class="flex md:hidden items-center justify-between sticky top-0  z-50 py-2 px-4 md:px-28 bg-dark" >
      <router-link to="/">
            <img src="../assets/images/qmbeauty_logo.png" alt="" class="w-28">
           
        </router-link>
        <button class="text-white border rounded-md p-2" @click="toggleMenu" v-if="!isOpen">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
          </i>
        </button>
        <button class="text-white border rounded-md p-2" @click="toggleMenu" v-if="isOpen">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
          </i>
        </button>
        <ul class="fixed w-full h-96 top-20 right-0 flex flex-col justify-start items-end gap-2   text-white bg-dark text-right px-4 pt-10" v-show="isOpen">
            <router-link :to="navLink.link"  v-for="navLink, index in navLinks" :key="index" class="block border-b py-2 px-1 w-full">{{navLink.name}}</router-link> 
        </ul>
    </nav>
  </div>
</template>
<script setup>
import {ref} from 'vue'


const  isOpen = ref(false)
 const navLinks = [
  {
    'name': 'Home',
    'link': '/'
  },
  {
    'name': 'Products',
    'link': '/products'
  },
  {
    'name': 'Services',
    'link': '/services'
  },
  {
    'name': 'Our Argents',
    'link': '/agents'
  },
  {
    'name': 'About',
    'link': '/about'
  }
 ]

 function toggleMenu(){
  if(isOpen.value == false ){
    return isOpen.value = true
  }
  else {
    return isOpen.value = false
  }
 }
</script>
<style scoped>
#app {
  text-align: center;
  color: #2c3e50;
}




nav a {
  font-weight: bold;
  color: #FFFFFF;
}

nav a.router-link-exact-active {
  color: #EC9704;
}
</style>
