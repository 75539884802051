<template>
    <div class="px-4 md:px-28">
        <div class="rounded-xl">
            <PageHeader :heade="products[indexOfMe].title"/>
            <div class="md:grid grid-cols-5  justify-center item-center gap-y-6   ">
                <img :src="products[indexOfMe].image" class="col-span-2">
                <div class="pt-6 col-span-3">
                    <subHeader :subHead="products[indexOfMe].title"/>
                    <p class="font-bold text-2xl py-4">{{products[indexOfMe].price}} Tsh</p>
                    <p>{{products[indexOfMe].description}}</p>
                    <div v-if="products[indexOfMe].ingredients.length">
                        <p class="font-bold capitalize  pb-2">ingredients:</p>
                        <span v-for="ingredients, i in products[indexOfMe].ingredients" :key="i">{{ ingredients }},</span>
                    </div>
                    <div v-if="products[indexOfMe].how_to_use.length">
                        <p class="font-bold capitalize  py-2">How to use:</p>
                        <p v-for="howToUse, i in products[indexOfMe].how_to_use" :key="i">{{ howToUse }},</p>
                    </div>
                    <div v-if="products[indexOfMe].benefits.length">
                        <p class="font-bold capitalize py-2">Benefits:</p>
                        <p v-for="benefits, i in products[indexOfMe].benefits" :key="i">{{ benefits }},</p>
                    </div>
                    <div v-if="products[indexOfMe].cautions.length">
                        <p class="font-bold capitalize py-2">Cautions:</p>
                        <p v-for="cautions, i in products[indexOfMe].cautions" :key="i">{{ cautions }},</p>
                    </div>
                </div>
            </div>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 flex-nowrap w-full py-10">
                <div v-for="images, i in products[indexOfMe].images" class="" :key="i">
                    <img :src="images.image" class="border-2 h-96 w-full object-cover">
                </div>
            </div>
            
        </div>
    </div>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { ref } from 'vue'
import PageHeader from '@/components/PageHeader.vue';
import subHeader from '@/components/SubHeader.vue'
            

const indexOfMe = ref(null)
const id = useRoute().params.id;


var products = [
    {
        'id': 'Qm Beauty Extra virgin coconut oil 8.50z 250ml',
        'title': 'Qm Beauty Extra virgin coconut oil 8.5indexOfMez 25indexOfMeml',
        'description': '100% Coconut Oil from Zanzibar Island Coconut oil is a great head-to-toe treatment which keeps the skin soft, smooth, and moisturized while nourishing the scalp and hair to give you a fresh look always.',
        'ingredients' : ['100% Coconut Oil from Zanzibar Island'],
        'how_to_use' : [],
        'benefits': ['Keeps the skin soft', 'Smooth, and moisturized while nourishing the scalp and hair to give you a fresh look always'],
        'cautions': [],
        "price": 25000,
        'image': require('../../assets/images/Qm_beauty_Extra_Virgin_Coconut_Oil_1.png'),
        'images': [
            { 'image': require('../../assets/images/Qm_beauty_Extra_Virgin_Coconut_Oil_2.png') },
            { 'image': require('../../assets/images/Qm_beauty_Extra_Virgin_Coconut_Oil_1.png') },
            { 'image': require('../../assets/images/Qm_beauty_Extra_Virgin_Coconut_Oil_3.png') },
            { 'image': require('../../assets/images/Qm_beauty_Extra_Virgin_Coconut_Oil.png') }
        ]
    },
    {
        'id': 'Qm Beauty natural scrub 7oz 200g',
        'title': 'Qm Beauty natural scrub 7oz 200g',
        'description': '3x1 scrub, shampoo and Moisturizer. Face, Body and Hair.',
        'ingredients' : ['Arabica coffee', 'Turmeric', 'Honey'],
        'how_to_use' : ['Wet your skin take scrub to your pain dilute with water/make a paste then apply for 3-5 minutes then rinse with cold water', 'How to use as shampoo', 'Squirt a small amount of scrub into the palm of your hand', ' Apply it to scalp and massage until it lathers', 'Work the scrub through your hair, but pay the most attention to the scalp', 'Rinse your hair and scalp completely. NB: suitable for shorter hair as it can come out easily'],
        'benefits': ['Moisturizer and protect your skin against UV light', 'Anti-aging', 'Anti-Bacterial', ' Brightening skin', 'Anti-dandruff'],
        'cautions': [],
        "price": 20000,
        'image': require('../../assets/images/Qm_Beauty_Natural_Scrub_1.png'),
        'images': [
            { 'image': require('../../assets/images/Qm_Beauty_Natural_Scrub_1.png') },
            { 'image': require('../../assets/images/Qm_Beauty_Natural_Scrub_1.png') },
            { 'image': require('../../assets/images/Qm_Beauty_Natural_Scrub_1.png') },
            { 'image': require('../../assets/images/Qm_Beauty_Natural_Scrub_1.png') }
        ]

    },
    {
        'id': 'Qm Beauty hair gel 7oz 200g',
        'title': 'Qm Beauty hair gel 7oz 200g',
        'description': '',
        'ingredients' : [],
        'how_to_use' : ['Wash and Dry your hair then gently massage small amount of oil into hair and scalp as needed.', ],
        'benefits': ['Prevent hair fall', 'Maintain health scalp', 'Rapidly hair growth', 'Thickens and strengthens hair', 'and Anti-dandruff.'],
        'cautions': ['Avoid contact with eyes. If product gets in eyes, rinse thoroughly with water.'],
        "price": 20000,
        'image': require('../../assets/images/Qmbeautyhairgel1.png'),
        'images': [
            { 'image': require('../../assets/images/Qmbeautyhairgel1.png') },
            { 'image': require('../../assets/images/Qmbeautyhairgel1.png') },
            { 'image': require('../../assets/images/Qmbeautyhairgel1.png') },
            { 'image': require('../../assets/images/Qmbeautyhairgel1.png') }
        ]
    },
    {
        'id': 'Qm Beauty Pure coconut oil 8.50z 250ml',
        'title': 'Qm Beauty Pure coconut oil 8.50z 250ml',
        'description': '100% Fresh coconut from Zanzibar Island, Coconut oil is a great head to toe treatment which keep the skin soft, smooth and moisturized while nourishing the scalp and hair to give you a fresh look always',
        'ingredients' : [],
        'how_to_use' : [],
        'benefits': ['Keep the skin soft', 'Smooth and moisturized while nourishing the scalp and hair to give you a fresh look always'],
        'cautions': [],
        "price": 12000,
        'image': require('../../assets/images/Qm_beauty_Pure_Coconut_Oil_2.png'),
        'images': [
            { 'image': require('../../assets/images/Qm_beauty_Pure_Coconut_Oil_2.png') },
            { 'image': require('../../assets/images/Qm_beauty_Pure_Coconut_Oil_2.png') },
            { 'image': require('../../assets/images/Qm_beauty_Pure_Coconut_Oil_2.png') },
            { 'image': require('../../assets/images/Qm_beauty_Pure_Coconut_Oil_2.png') },
            { 'image': require('../../assets/images/Qm_beauty_Pure_Coconut_Oil_2.png') }
        ]
    },
    {
        'id': 'Qm Beauty Regular coconut oil 8.5oz 250ml',
        'title': 'Qm Beauty Regular coconut oil 8.5oz 250ml',
        'description': 'Coconut oil is great head to toe treatment which keeps nourishing the scalp and hair to give you fresh look always.',
        'ingredients' : ['100% Coconut Oil from Zanzibar Island blended with essential oils'],
        'how_to_use' : [],
        'cautions': [],
        'benefits': [],
        "price": 15000,
        'image': require('../../assets/images/Regular_coconut_oil1.png'),
        'images': [
            { 'image': require('../../assets/images/Regular_coconut_oil1.png') },
            { 'image': require('../../assets/images/Regular_coconut_oil1.png') },
            { 'image': require('../../assets/images/Regular_coconut_oil1.png') },
            { 'image': require('../../assets/images/Regular_coconut_oil1.png') }
        ]
    },
    {
        'id': 'Qm Beauty Natural Glowing face cream 3.4oz 100ml',
        'title': 'Qm Beauty Natural Glowing face cream 3.4oz 100ml',
        'description': 'With Vitamin E & shea-butter',
        'ingredients' : ['Water', 'Cucumber', 'Licorice', 'Essential oils', 'Preservative'],
        'how_to_use' : ['Cleanse your face', 'apply', 'massage well until absorbed'],
        'benefits': ['Reduce appearance of dark spots', 'Reduce acne scars', 'Skin tightening', 'Skin glowing'],
        'cautions': ['Avoid contact with eyes'],
        "price": 25000,
        'image': require('../../assets/images/Qm_beauty_Natural_glowing_face_3.png'),
        'images': [
            { 'image': require('../../assets/images/Qm_beauty_Natural_glowing_face_3.png') },
            { 'image': require('../../assets/images/Qm_beauty_Natural_glowing_face_3.png') },
            { 'image': require('../../assets/images/Qm_beauty_Natural_glowing_face_3.png') },
            { 'image': require('../../assets/images/Qm_beauty_Natural_glowing_face_3.png') }

        ]
    },
    {
        'id': 'Qm Beauty Carrots & turmeric Body Oil 8.5oz 250ml',
        'title': 'Qm Beauty Carrots & turmeric Body Oil 8.5oz 250ml',
        'description': 'Highly valuable for your daily body care',
        'ingredients' : ['Carrots', 'Coconut Oil', 'Essential Oils', 'Turmeric'],
        'how_to_use' : ['Cleanse your body then apply this miracle body oil for healthy skin'],
        'benefits': ['Help to remove wrinkles', 'Soothes sunburn', 'Improves skin tone', 'Maintains healthy skin', 'Nourishes skin','Makes your skin smooth'],
        'cautions': ['Avoid contact with eyes'],
        "price": 20000,
        'image': require('../../assets/images/Qmbeautycarrotsandturmericbodyoil_1.png'),
        'images': [
            { 'image': require('../../assets/images/Qmbeautycarrotsandturmericbodyoil_1.png') },
            { 'image': require('../../assets/images/Qmbeautycarrotsandturmericbodyoil_1.png') },
            { 'image': require('../../assets/images/Qmbeautycarrotsandturmericbodyoil_1.png') },
            { 'image': require('../../assets/images/Qmbeautycarrotsandturmericbodyoil_1.png') }
        ]
    },
];

indexOfMe.value = products.findIndex(object => {
    return object.id === id;
});



console.log('indexValue ' + indexOfMe.value)

</script>
<style >

</style>